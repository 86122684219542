import React from 'react'
import { Helmet } from 'react-helmet'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Intro from '../components/sections/intro'
import HomeContent from '../components/sections/home-content'
import Partners from '../components/sections/partners'

const phone = '866-545-3239'

const IndexPage = ({ location }) => (
  <Layout phone={phone} location={location} showCountDown>
    <SEO
      title="Medicare Supplement Plans - Medicare Companion"
      keywords={[`medicare`, `insurance`, `plans`, `affordable`, `supplement`]}
    />
    <Header phone={phone} />
    <Intro showTitle={true}>
      <div className="form-box">
        <div className="contacts-form alt text-center">
          <h4 className="title">Have questions about a plan? Give us a call!</h4>
          <a href={`tel:${phone}`} className="btn with-icon mt1">
            <span className="icon-phone" /> <span className="text">{phone}</span>
          </a>
        </div>
      </div>
    </Intro>
    <main id="main">
      <Partners />
      <HomeContent phone={phone} noScrollCTA />
    </main>
  </Layout>
)

export default IndexPage
